import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default class Icons extends Component {

  constructor(props) {
    super(props);
    this.state = { searchQuery: null, activeIconId: null };
  }

  _performSearch = (e) => {
    this.setState({ ...this.state, searchQuery: e.target.value });
  }

  _searchIcons = () => {
    return (
      <div className="row">
        <div className="col-md-8 col-md-offset-2">
          <form>
            <div className="form-group row">
              <div className="col-md-2 col-md-offset-1">
                <label htmlFor="search-icon">Search Icons</label>
              </div>
              <div className="col-md-8">
                <div className="form-control-wrapper">
                  <input onChange={this._performSearch} className="form-control empty" placeholder='ex. "alarm" or "device"' id="search-icon" />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }

  _category = (category, index) => {
    const filteredIcons = category.icons.filter(
      icon => !this.state.searchQuery || icon.name.indexOf(this.state.searchQuery) > -1
    );
    if (filteredIcons.length === 0) {
      return null;
    }

    return (
      <div key={index} className="icon__list-items">
        <div className="row">
          <div className="col-md-12">
            <h2>— {category.name} —</h2>
          </div>
          { filteredIcons.map(icon => this._icon(category, icon)) }
        </div>
      </div>
    );
  }

  _showIconInfo = (icon) => {
    this.setState({ ...this.state, activeIconId: icon.id });
  }

  _hideIconInfo = (icon) => {
    this.setState({ ...this.state, activeIconId: null });
  }

  _icon = (category, icon) => {
    const htmlClass = classnames(
      'col-lg-1 col-md-2 col-sm-3 col-xs-4 icon__list-item',
      { active: icon.id === this.state.activeIconId }
    );
    return (
      <div
        key={icon.id}
        className={htmlClass}
        onClick={() => this._showIconInfo(icon)}
        onMouseLeave={this._hideIconInfo}
      >
        <div className="icon__list-item-icon">
          <i className="material-icons md-36">{icon.ligature}</i>
        </div>
        <div className="details-wrapper">
          <div className="details shadow" >
            <input type="text" className="icon-name" readOnly="" onClick={this._selectOnClick} defaultValue={`<i className="material-icons">${icon.ligature}</i>`} />
            <input type="text" className="icon-name" readOnly="" onClick={this._selectOnClick} defaultValue={icon.codepoint} />
            <ul>
              <li>
                <a
                  href={`https://raw.githubusercontent.com/google/material-design-icons/master/${category.name}/2x_web/${icon.id}_black_48dp.png`}
                  download={`${icon.id}.png`}
                  title="Download as PNG"
                  target="_blank"
                >PNG</a>
              </li>
              <li>
                <a
                  href={`https://raw.githubusercontent.com/google/material-design-icons/master/${category.name}/svg/production/${icon.id}_48px.svg`}
                  download={`${icon.id}.svg`}
                  title="Download as SVG"
                  target="_blank"
                >SVG</a>
              </li>
              <li>
                <a
                  href={`https://raw.githubusercontent.com/google/material-design-icons/master/${category.name}/2x_ios/${icon.id}_black_48dp.png`}
                  download={`${icon.id}_ios.png`}
                  title="Download for iOS"
                  target="_blank"
                >iOS</a>
              </li>
              <li>
                <a
                  href={`https://raw.githubusercontent.com/google/material-design-icons/master/${category.name}/drawable-xhdpi/${icon.id}_black_48dp.png`}
                  download={`${icon.id}_android.png`}
                  title="Download for Android"
                  target="_blank"
                >Android</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }

  _selectOnClick = e => e.target.select()

  render() {
    return (
      <div>
        <h1>Hello</h1>
        { this._searchIcons() }
        { this.props.categories.map(this._category) }
      </div>
    );
  }
}
