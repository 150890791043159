import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class TwitterShare extends Component {
  static propTypes = {
    text:     PropTypes.string.isRequired,
    via:      PropTypes.string,
    related:  PropTypes.string,
    url:      PropTypes.string,
    hashtags: PropTypes.string,
  }

  static defaultProps = {
    via:      null,
    related:  null,
    url:      null,
    hashtags: null
  }

  componentDidMount() {
    const width  = 575;
    const height = 400;
    const left   = ($(window).width()  - width)  / 2;
    const top    = ($(window).height() - height) / 2;
    const option = `status=1,width=${width},height=${height},top=${top},left=${left}`;
    const url    = `http://twitter.com/share?${this._buildShareUrl()}`;

    window.open(url, 'Share', option);
  }

  _buildShareUrl() {
    let tweetUrl = '';
    if (this.props.text) {
      tweetUrl += `text=${this.props.text}&`;
    }
    if (this.props.via) {
      tweetUrl += `via=${this.props.via}&`;
    }
    if (this.props.related) {
      tweetUrl += `related=${this.props.related}&`;
    }
    if (this.props.text) {
      tweetUrl += `hashtags=${this.props.hashtags}&`;
    }

    return tweetUrl.slice(0, -1);
  }

  render() {
    return null;
  }
}
