import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class CarbonAd extends Component {
  constructor(props) {
    super(props);
    this.id = 'carbon_js';
  }

  componentDidMount() {
    this.element = document.getElementById(this.id);
    if (!!window._carbonads && this.element) {
      window._carbonads.reload(this.element);
      // NOTE: this line avoid rerender of card by default cards.js
      // it relyes on _carbon_where element set by window._carbonads.reload
      this.element.addEventListener("DOMNodeInserted", () => window._carbon_where = null, false);
    }
  }

  componentWillUnmount() {
    this.element.innerHTML = '';
  }

  render = () => <div id={this.id} className={this.props.className} />
}
