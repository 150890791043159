import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Alert from 'components/Alert';

export default class Colors extends Component {
  static propTypes = {
    colors: PropTypes.array.isRequired
  }

  constructor(props) {
    super(props);
    this.state = {
      activeColor:   null,
      copied:        false,
      copiedMessage: 'test'
    };
  }

  _activateColor = (color) => this.setState({ ...this.state, activeColor: color.name })
  _showCopied = (hex) => {
    this.state.copied = true;
    this.state.copiedMessage = `${hex} is now copied to your clipboard`;
    this.forceUpdate();
  }

  _shade = (color, shade) => (
    <CopyToClipboard key={shade.hex} text={shade.hex} onCopy={() => this._showCopied(shade.hex)} >
      <div
        className="color__shade"
        style={{ background: shade.hex, height: `${500/color.shades.length}px` }}
      >
        <span style={{ color: shade.contrast }} className="color__strength">{shade.strength}</span>
        <span style={{ color: shade.contrast }} className="color__hex">{shade.hex}</span>
      </div>
    </CopyToClipboard>

  )

  _color = (color, index) => (
    <div
      key={index}
      className={classnames('color', { active: this.state.activeColor === color.name })}
      style={{ background: color.default.hex }}
      onClick={() => this._activateColor(color)}
    >
      { this.state.activeColor === color.name ?
        color.shades.map(shade => this._shade(color, shade)) :
        <div className="color__name" style={{ color: color.default.contrast }}>{color.name}</div>
      }
    </div>
  )

  render = () => (
    <div className="shadow">
      <div className="clearfix">
        <Alert open={this.state.copied} message={this.state.copiedMessage} />
        { this.props.colors.map(this._color) }
      </div>
    </div>
  )
}
