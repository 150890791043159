import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import capitalize from 'capitalize';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Alert from 'components/Alert';
import TwitterShare from 'components/TwitterShare';
import $ from 'jquery';

export default class PaletteDetails extends Component {
  static propTypes = {
    palette: PropTypes.object.isRequired,
    rootUrl: PropTypes.string.isRequired
  }

  constructor(props) {
    super(props);
    this.state = {
      showDownloadOptions: false,
      showPaletteColors:   true,
      copied:              false,
      copiedMessage:       '',
      shareViaTwitter:     false
    };
  }

  componentDidMount() {
    $('.theme-wrapper').height(($(window).height() - 60));
  }

  _showDownload = () => this.setState({ showDownloadOptions: true })

  _downloadOptions = ({primary, accent}) => (
    <span className="animated fadeIn">
      <a href={`/download.css/${primary.key}/${accent.key}`} title="Download CSS format">CSS</a>
      <a href={`/download.sass/${primary.key}/${accent.key}`} title="Download SASS format">SASS</a>
      <a href={`/download.less/${primary.key}/${accent.key}`} target="_blank" title="Download LESS format">LESS</a>
      <a href={`/download.svg/${primary.key}/${accent.key}`} title="Download SVG format">SVG</a>
      <a href={`/download.xml/${primary.key}/${accent.key}`} title="Download XML format">XML</a>
      <a href={`/download.png/${primary.key}/${accent.key}`} target="_blank" title="Download PNG format">PNG</a>
      <a href={`/download.polymer/${primary.key}/${accent.key}`} title="Download Polymer format">POLYMER</a>
    </span>
  )

  _tweetBtn = ({palette, rootUrl}) => {
    const { primary, accent } = palette;
    return (
      <a href="javascript:;" onClick={() => this.setState({ ...this.state, shareViaTwitter: true})}>
        { this.state.shareViaTwitter &&
          <TwitterShare
            text={`Check out my favorite Material Design Palette — ${capitalize(primary.name)} and ${capitalize(accent.name)}`}
            via='materialup'
            related='materialup'
            url={`${rootUrl}${primary.key}/${accent.key}`}
            hashtags='MaterialDesign'
          />
        }
        <i className="material-icons">share</i>
        TWEET
      </a>
    )
  }

  _hidePaletteColors = () => this.setState({ ...this.state, showPaletteColors: false })
  _showPaletteColors = () => this.setState({ ...this.state, showPaletteColors: true })

  _showCopied = (color) => {
    this.setState({ ...this.state, copied: true, copiedMessage: `${color} is now copied to your clipboard`})
  }

  _paletteColor = ({ color, contrastColor, title }, index) => (
    <CopyToClipboard key={index} text={color.toUpperCase()} onCopy={() => this._showCopied(color.toUpperCase())}>
      <div className="theme-palette-color" data-clipboard-text="}">
        <div
          style={{ background: color }}
          className={contrastColor ? `theme-palette-color-inner theme-palette-color-inner--${contrastColor}` : ''}
        >
          <span>{title}</span>
          <strong>{color}</strong>
        </div>
      </div>
    </CopyToClipboard>
  )

  _palette({ palette }) {
    const palleteColors = [
      {color: palette.darkPrimary, contrastColor: palette.darkPrimaryContrast, title: 'Dark primary color'},
      {color: palette.lightPrimary, contrastColor: palette.lightPrimaryContrast, title: 'Light primary color'},
      {color: palette.defaultPrimary, contrastColor: palette.defaultPrimaryContrast, title: 'Primary color'},
      {color: palette.textPrimary, contrastColor: palette.textPrimaryContrast, title: 'Text / Icons'},
      {color: palette.accentPrimary, contrastColor: palette.accentPrimaryContrast, title: 'Accent color'},
      {color: '#212121', title: 'Primary text'},
      {color: '#757575', title: 'Secondary text'},
      {color: '#BDBDBD', title: 'Divider color'},

    ]
    return (
      <div className="animated">
        <div className="theme-palette-colors clearfix">
          {palleteColors.map(this._paletteColor)}
        </div>
      </div>
    );
  }

  render () {
    return (
      <div className="theme-palette">
        <Alert open={this.state.copied} message={this.state.copiedMessage} />
        <div className="theme-palette__header clearfix">
          <span className="theme-palette__header-title">
            Your Palette
            {!this.state.showPaletteColors && <i className="material-icons" onClick={this._showPaletteColors}>expand_more</i>}
            {this.state.showPaletteColors && <i className="material-icons" onClick={this._hidePaletteColors}>expand_less</i>}
          </span>
          <span className="theme-palette__header-actions">
            { this.state.showDownloadOptions && this._downloadOptions(this.props.palette) }
            { !this.state.showDownloadOptions &&
                <span>
                  <a href="javascript:;" onClick={this._showDownload}>
                    <i className="material-icons">file_download</i>DOWNLOAD
                  </a>
                </span>
            }
            { this._tweetBtn(this.props) }
          </span>
        </div>
        {this.state.showPaletteColors && this._palette(this.props)}
      </div>
    );
  }
}
