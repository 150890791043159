import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames'

export default class Alert extends React.Component {
  static defaultProps = {
    autoHideTimeout: 10000,
    position: 'bottom',
  };

  static propTypes = {
    autoHideTimeout: PropTypes.number,
    classes:         PropTypes.string,
    message:         PropTypes.node.isRequired,
    open:            PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  componentDidMount() {
    if (!this.props.open) return;
    this._show();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open === this.state.open) return;
    if (nextProps.open) this._show();
    else this._hide();
  }

  componentWillUnmount() {
    this.timerAutoHideId && clearTimeout(this.timerAutoHideId);
  }


  setAutoHideTimer() {
    if (this.props.autoHideTimeout > 0) {
      clearTimeout(this.timerAutoHideId);
      this.timerAutoHideId = setTimeout(this._hide, this.props.autoHideTimeout);
    }
  }

  _show() {
    setTimeout(() => {
      this.setState({ open: true });
      this.setAutoHideTimer();
    }, 10);
  }
  _hide = () => this.setState({ open: false });


  render() {
    if (!this.state.open) {
      return null;
    }

    const htmlClass = classnames(
      'alert-message',
      `alert-message--${this.props.position}`,
      { 'alert-message--open': this.state.open },
      this.props.classes
    )

    return (
      <div className={htmlClass}>
        <div className="alert-message__body" dangerouslySetInnerHTML={{__html: this.props.message}} />
        <div className="alert-message__close" role="button" onClick={ this._hide }>
          <span className="material-icons">close</span>
        </div>
      </div>
    );
  }
}
